import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { useRegions } from '../../../hooks';

const DropdownState = ({ isD1 = false, ...props }) => {
  let states = useRegions();

  if (props.filterIds && props.filterIds.length > 0) {
    states = states.filter((state) => props.filterIds.indexOf(state.id) >= 0);
  }

  if (isD1) {
    states.shift();
  }

  return (
    <>
      <Select
        {...props}
        className={classNames('react-select', {
          'is-invalid': props.isInvalid,
        })}
        classNamePrefix="select"
        value={
          props.value && {
            ...states?.find(
              (o) =>
                o.name === props.value ||
                o.name === props.value.sourceState ||
                o.id === props.value ||
                o.id === props.value.sourceState
            ),
          }
        }
        options={states}
        getOptionLabel={(option) => `${option.name}`}
        getOptionValue={(option) => `${option.id}`}
        noOptionsMessage={() => 'State not found!'}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </>
  );
};

export default DropdownState;
