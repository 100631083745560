import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import {
  Button,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Link,
  makeStyles,
  Modal,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import * as moment from 'moment';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { faCaretDown, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeliveryDocListModal from './DocLIstModal';
import DocRejectListModal from './DocRejectLIstModal';
import { DeliveryService } from '../../../../../features/Delivery/delivery.service';
import { useAuthority } from '../../../../../shared/Components/HasAuthority/HasAuthority';
import { AUTHORITY } from '../../../../../shared/Constants/Constants';
import DeliveryUploadDocs from '../DeliveryUploadDocs';
import { isEmpty } from 'lodash';
import GenerateDocModal from './GenerateDocModal';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as MarkerIcon } from '../../../../../assets/img/_App/marker.svg';

const useStyles = makeStyles((theme) => ({
  zoomIntext: {
    background: '#475f64',
    position: 'absolute',
    zIndex: '999',
    left: '30px',
    right: '30px',
    color: 'white',
    marginTop: '10px',
    padding: '10px',
    borderRadius: '8px',
    fontFamily: 'font-semibold',
  },
  rejectButton: {
    width: '100%',
    backgroundColor: '#ff381a',
    color: '#ffffff',
    margin: '10px',
    fontFamily: 'font-semibold',
  },
  approveButton: {
    margin: '10px',
    width: '100%',
    backgroundColor: '#005dff',
    color: '#ffffff',
    fontFamily: 'font-semibold',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '15px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  boxShadow: { boxShadow: 'none' },
  card1: {
    height: '100%',
    borderRadius: 10,
    padding: '0px',
  },
  card2: {
    height: '100%',
    borderRadius: 10,
    backgroundColor: '#848884',
    padding: '16px',
  },
  mapContent: {
    height: '100%',
    padding: '0px',
    paddingBottom: '0px !important',
  },
  detailContent: {
    color: '#fff',
    padding: '5px',
  },
  documentLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '74vh',
    fontSize: '17px',
    fontWeight: 'bold',
  },
  pdfSection: {
    width: '100%',
    height: '78vh',
    overflow: 'auto',
  },
}));

const DocumentTypeModal = ({
  setSelectedDoc,
  selectedDocument,
  docTypeList,
  doFetchDelivery,
  delivery,
  // isView,
  setVisible,
  docList,
  doUpdateDelivery,
  docConfigList,
}) => {
  const { hasAuth } = useAuthority();
  const isSupervsior = hasAuth([AUTHORITY.PRO, AUTHORITY.SERVICE_PROVIDER]);
  const isPRO = hasAuth([AUTHORITY.PRO]);
  const slider = useRef();
  const workerRef = useRef();
  const classes = useStyles();
  let renderPdfDoc;
  const [documentList, setDocumentList] = useState(docList);
  const [lrNumber, setLRNumber] = useState();
  const [showDocListModal, setDocListModal] = useState(false);
  const [showRejectDocListModal, setRejectDocListModal] = useState(false);
  const [closeZoomText, setCloseZoomText] = useState(true);
  const [docType, setDocType] = useState();
  const [allDocModal, setAllDocModal] = useState();
  const [generateDocModal, setGenerateDocModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [documentArray, setDocumentArray] = useState([]);
  const [documentArrayLoading, setDocumentArrayLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(
    docList?.findIndex((e) => e.document.fileName === selectedDocument?.document?.fileName)
  );

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentIndex(current),
  };

  useEffect(() => {
    slider.current.slickGoTo(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (!isView()) {
      setDocType(selectedDocument?.document?.type);
    }
  }, [docTypeList]);

  const isView = () => {
    const isPROInReviewDoc = documentList[currentIndex]?.status === 'IN_REVIEW' ? false : true;
    const isDocNotApprovedFromPro =
      (delivery?.assignedDeliveries[0]?.status === 'IN_REVIEW' &&
        documentList[currentIndex]?.status !== 'APPROVED') ||
      delivery?.assignedDeliveries[0]?.status === 'IN_PROGRESS'
        ? false
        : true;
    return !isPRO ? isDocNotApprovedFromPro : isPROInReviewDoc;
  };

  const handleOnClose = () => {
    setDocListModal(false);
  };

  const handleOnSelect = (e) => {
    setDocType(e);
    updateDocType(e);
    setDocListModal(false);
  };

  const handleRejectOnClose = () => {
    setRejectDocListModal(false);
  };

  const handleRejectOnSubmit = (e, others) => {
    if (e === 'others') {
      doRejectDocument(others);
    } else {
      doRejectDocument(e);
    }
    setRejectDocListModal(false);
  };

  /**
   * called when request for change of doctype
   * @param docType
   */
  const updateDocType = async (docType) => {
    try {
      const res = await DeliveryService.updateDocumentType({
        deliveryDocumentId: selectedDocument.id,
        docType: docType,
      });
      (delivery.status === 'APPROVED' || delivery.status === 'CREATED') &&
      delivery?.unallocatedQty === 0 &&
      delivery?.itemCategoryName !== 'E-Waste' &&
      isPRO
        ? await DeliveryService.updateStatus({
            ids: delivery.id,
          })
        : await DeliveryService.updateSPDeliveryStatus({
            ids: delivery.id,
          });

      if (res) {
        doFetchDelivery();
        const sDoc = { ...selectedDocument };
        sDoc.document.type = docType;
        setSelectedDoc(sDoc);
        const doctypelist = delivery?.deliveryDocuments?.filter((e) => e.document.type === docType);
        setDocumentList(doctypelist);
        setCurrentIndex(doctypelist?.findIndex((e) => e.id === selectedDocument.id));
      }
    } catch (error) {}
  };

  /**
   * called when request for change of doctype
   * @param payload
   */
  const doRejectDocument = async (reason) => {
    const payload = {
      deliveryDocuments: [selectedDocument.id],
      comment: reason,
      deliveryId: delivery.id,
    };
    try {
      await DeliveryService.rejectDocument(payload);
    } catch (error) {}
    await doFetchDelivery();
    if (isPRO && delivery?.deliveryDetails?.pickUpInvoiceNumber === null) {
      setShowModal(true);
    } else {
      setVisible(false);
    }
  };

  /**
   * called when request for change of doctype
   * @param payload
   */
  const doApproveDocument = async () => {
    const payload = { id: selectedDocument.id, deliveryId: delivery.id };
    try {
      await DeliveryService.approveDocument(payload);
    } catch (error) {}
    await doFetchDelivery();
    if (isPRO && delivery?.deliveryDetails?.pickUpInvoiceNumber === null) {
      setShowModal(true);
    } else {
      setVisible(false);
    }
  };

  const isDisableApprove =
    isPRO &&
    (docTypeList?.find((e) => e._id === docType)?.name === undefined ||
      !delivery?.status === 'IN_REVIEW');

  const isDisableReject =
    isPRO &&
    (docTypeList?.find((e) => e._id === docType)?.name === undefined ||
      !delivery?.status === 'IN_REVIEW');

  const isDisableForSp =
    !isPRO &&
    (docTypeList?.find((e) => e._id === docType)?.name === undefined ||
      documentList[currentIndex]?.status === 'APPROVED' ||
      (documentList[currentIndex]?.status === 'REJECTED' &&
        delivery?.assignedDeliveries[0]?.status === 'IN_PROGRESS') ||
      (delivery?.assignedDeliveries[0]?.status === 'IN_REVIEW' &&
        documentList[currentIndex]?.status === 'IN_REVIEW'));

  const displayDocName =
    docType !== undefined ? docTypeList?.find((e) => e._id === docType)?.name : '';

  const handleChangeType = () => {
    setAllDocModal(true);
  };

  const handleLRNumberChange = () => {
    const deliveryDetails = delivery?.deliveryDetails;
    deliveryDetails.pickUpLrNumber = lrNumber;
    doUpdateDelivery({ ...delivery, deliveryDetails });
    doFetchDelivery();
  };

  const onViewClick = (e) => {
    const typeList = delivery?.deliveryDocuments?.filter((doc) => doc.document.type === e.type);
    setDocumentList(typeList);
    setSelectedDoc(typeList[0]);
    setCurrentIndex(0);
    setAllDocModal(false);
  };
  /**
   * This api call is used to update delivery
   * @return {Promise<*>}
   */
  const doGenerateEwaybillEInvoice = async () => {
    try {
      await DeliveryService.generateInvoice({
        id: delivery?.id,
      });
      doFetchDelivery();
      setGenerateDocModal(false);
      setVisible(false);
    } catch (error) {
      // setSubmitInProgress(false);
    }
  };

  useEffect(() => {
    const updatedArray = [];
    let count = 0;
    const docs = documentList?.map(async (specificDocument, index) => {
      if (specificDocument?.document?.contentType === 'application/pdf') {
        const result = await convertToImages(specificDocument?.document?.viewUrl);
        updatedArray.push({
          docId: specificDocument?.document?.id,
          pdfDoc: result,
        });
      }
      count++;
      if (count === documentList?.length && !isEmpty(updatedArray)) {
        setDocumentArray(updatedArray);
        if (currentIndex) slider.current.slickGoTo(currentIndex);
        setDocumentArrayLoading(false);
      }
    });
  }, [documentList]);

  const convertToImages = async (pdf) => {
    setDocumentArrayLoading(true);
    let pdfjsLib = window['pdfjs-dist/build/pdf'];
    // The workerSrc property shall be specified.
    pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
    //https://github.com/mozilla/pdf.js/issues/7333#issuecomment-219532850
    const pdfLoadingTask = pdfjsLib.getDocument(pdf);
    workerRef.current = workerRef.current || pdfLoadingTask._worker;
    const pdfFile = await pdfLoadingTask.promise;
    const numberOfPages = pdfFile?._pdfInfo?.numPages;
    let currentPage = 1;
    const imageArray = [];
    while (currentPage <= numberOfPages) {
      const blob1 = await getPage(currentPage, pdfFile);
      imageArray.push(blob1);
      currentPage++;
    }
    return Promise.resolve(imageArray);
  };

  // read pdf page and convert to blob
  const getPage = (num, pdf) => {
    return new Promise(async (resolve, reject) => {
      const page = await pdf.getPage(num);
      const scale = '1.5';
      const viewport = page.getViewport({ scale: scale });
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderPdf = page.render({ canvasContext: canvasContext, viewport: viewport });
      renderPdf.promise.then(function () {
        canvas.toBlob(
          function (blob) {
            resolve(canvas.toDataURL('image/jpeg', blob));
          },
          'image/jpeg',
          0.43
        );
      });
    });
  };
  return (
    <div className="t">
      {closeZoomText && (
        <div className={classes.zoomIntext}>
          <Typography>
            Use two fingers to zoom the image{' '}
            <Link
              className="ml-2"
              variant="body2"
              onClick={() => setCloseZoomText(false)}
              style={{ color: '#ff8700', fontFamily: 'font-semibold' }}>
              {'OKAY'}
            </Link>
          </Typography>
        </div>
      )}
      <Slider
        ref={slider}
        style={{ height: isView() || !isSupervsior ? '85vh' : '60vh' }}
        {...settings}>
        {documentList?.map((doc) => {
          return (
            <>
              {doc?.document?.contentType === 'application/pdf' ? (
                documentArrayLoading ? (
                  <p className={classes.documentLoading}>Document loading ... please wait</p>
                ) : (
                  <div className={classes.pdfSection}>
                    {
                      ((renderPdfDoc = documentArray?.find(
                        (pdfDoc) => pdfDoc?.docId === doc?.document?.id
                      )),
                      renderPdfDoc?.pdfDoc?.map((document) => (
                        <img width="100%" height="100%" src={document} alt="test" />
                      )))
                    }
                  </div>
                )
              ) : (
                <TransformWrapper
                  className="h-100"
                  defaultScale={1}
                  defaultPositionX={200}
                  defaultPositionY={100}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    //   <React.Fragment>
                    <TransformComponent>
                      <div
                        style={{
                          height: isView() || !isSupervsior ? '78vh' : '70vh',
                          width: '100vw',
                        }}>
                        <img
                          className="h-100"
                          width="100%"
                          height="100%"
                          src={doc?.document?.viewUrl}
                          alt="test"
                        />
                      </div>
                    </TransformComponent>
                  )}
                </TransformWrapper>
              )}
              {!isEmpty(JSON.parse(doc?.document?.metadata)) && (
                <>
                  <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={4} md={3} sm={3}>
                      <Card className={classes.card1}>
                        <CardContent className={classes.mapContent}>
                          <GoogleMapReact
                            center={{ lat: doc?.document?.latitude, lng: doc?.document?.longitude }}
                            zoom={15}
                            bootstrapURLKeys={{
                              key: 'AIzaSyBCjZ5nnSS7CkkEE2Dwvkb0uCoCbKzTAY0',
                              libraries: ['places', 'geometry'],
                            }}>
                            <MarkerIcon
                              lat={doc?.document?.latitude}
                              lng={doc?.document?.longitude}
                            />
                          </GoogleMapReact>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={8} md={9} sm={9}>
                      <Card className={classes.card2}>
                        <CardContent className={classes.mapContent}>
                          <Typography className={classes.detailContent}>
                            {JSON.parse(doc?.document?.metadata)?.address}
                          </Typography>
                          <Typography className={classes.detailContent}>
                            Lat {doc?.document?.latitude}
                          </Typography>
                          <Typography className={classes.detailContent}>
                            Long {doc?.document?.longitude}
                          </Typography>
                          <Typography className={classes.detailContent}>
                            {moment(doc?.document?.modifiedAt).format('MM/DD/YY hh:mm a')}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          );
        })}
      </Slider>
      {!isView() && isSupervsior && (
        <div style={{ width: '100%', position: 'fixed', bottom: 0 }}>
          {!isEmpty(JSON.parse(selectedDocument?.document?.metadata)) && (
            <Grid container className={classes.root} spacing={1}>
              <Grid item xs={4} md={3} sm={3}>
                <Card className={classes.card1}>
                  <CardContent className={classes.mapContent}>
                    <GoogleMapReact
                      center={{
                        lat: selectedDocument?.document?.latitude,
                        lng: selectedDocument?.document?.longitude,
                      }}
                      zoom={15}
                      bootstrapURLKeys={{
                        key: 'AIzaSyBCjZ5nnSS7CkkEE2Dwvkb0uCoCbKzTAY0',
                        libraries: ['places', 'geometry'],
                      }}>
                      <MarkerIcon
                        lat={selectedDocument?.document?.latitude}
                        lng={selectedDocument?.document?.longitude}
                      />
                    </GoogleMapReact>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8} md={9} sm={9}>
                <Card className={classes.card2}>
                  <CardContent className={classes.mapContent}>
                    <Typography className={classes.detailContent}>
                      {JSON.parse(selectedDocument?.document?.metadata)?.address}
                    </Typography>
                    <Typography className={classes.detailContent}>
                      Lat {selectedDocument?.document?.latitude}
                    </Typography>
                    <Typography className={classes.detailContent}>
                      Long {selectedDocument?.document?.longitude}
                    </Typography>
                    <Typography className={classes.detailContent}>
                      {moment(selectedDocument?.document?.modifiedAt).format('MM/DD/YY hh:mm a')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          <Paper elevation="0" className="pl-3 pr-3 pt-3">
            <Typography style={{ marginBottom: '5px' }}>
              TAG IMAGE TO DOCUMENT{' '}
              <FontAwesomeIcon
                style={{ float: 'right' }}
                onClick={() => (isPRO ? null : handleChangeType())}
                icon={faFile}
              />
            </Typography>

            <FormControl
              disabled={isPRO}
              style={{ marginBottom: 4 }}
              fullWidth
              variant="outlined"
              onClick={() => setDocListModal(true)}>
              <FormLabel label={'Select Documents'} />
              <TextField
                disabled={isPRO}
                InputProps={{
                  endAdornment: (
                    <InputAdornment style={{}} position="end">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                value={displayDocName}
                style={{ marginBottom: 8 }}
                placeholder={'Select Document type'}
                variant="outlined"
              />
            </FormControl>
            {documentList?.[currentIndex]?.document?.type === 'PICKUP_LR' && (
              <div>
                <FormControl style={{ width: '75%', marginRight: '5px', marginBottom: '0px' }}>
                  <FormLabel label={'LR Number'} />
                  <TextField
                    disabled={delivery?.status === 'IN_REVIEW' && isPRO}
                    placeholder={'Enter LR Number'}
                    variant="outlined"
                    onChange={(e) => setLRNumber(e.target.value)}
                  />
                </FormControl>
                <Button onClick={() => handleLRNumberChange()} variant="contained">
                  Done
                </Button>
              </div>
            )}
          </Paper>
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              className={classes.rejectButton}
              disabled={isDisableReject || isDisableForSp}
              onClick={() => setRejectDocListModal(true)}>
              Reject
            </Button>
            <Button
              variant="contained"
              className={classes.approveButton}
              disabled={isDisableApprove || isDisableForSp}
              onClick={() => doApproveDocument()}>
              Approve
            </Button>
          </div>
        </div>
      )}
      <DeliveryDocListModal
        open={showDocListModal}
        onClose={handleOnClose}
        onSelect={handleOnSelect}
        docTypeList={docTypeList}
      />
      <DocRejectListModal
        open={showRejectDocListModal}
        onClose={handleRejectOnClose}
        onSelect={handleRejectOnSubmit}
      />
      <Modal
        onClose={() => setAllDocModal(false)}
        aria-labelledby="modal-title"
        open={allDocModal}
        setVisible={setAllDocModal}>
        <Paper elevation={0} style={{ position: 'fixed', bottom: 0, width: '100%' }}>
          <Typography className="pl-4 pt-4" variant="subtitle1">
            All Documents
          </Typography>
          <DeliveryUploadDocs
            docConfigList={docConfigList}
            deliveryDocuments={delivery?.deliveryDocuments}
            delivery={delivery}
            isView={true}
            onViewClick={onViewClick}
          />
        </Paper>
      </Modal>
      <GenerateDocModal
        open={generateDocModal && isPRO}
        onClose={() => setGenerateDocModal(false)}
        setGenerateDocModal={setGenerateDocModal}
        showModal={showModal}
        docConfigList={docConfigList}
        deliveryDocuments={delivery?.deliveryDocuments}
        delivery={delivery}
        setVisible={setVisible}
        doGenerateEwaybillEInvoice={doGenerateEwaybillEInvoice}
      />
    </div>
  );
};

export default DocumentTypeModal;
