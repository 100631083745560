import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';
import axios from 'axios';

function getSummaryData(urls, options, params) {
  return axios.all(urls.map((url) => API.get(url, options, params))).then(
    axios.spread((res1, res2) => {
      return {
        orderSummary: res1.data,
        deliverySummary: res2.data,
      };
    })
  );
}

const fetchTargetExecution = async (queryParams) => {
  return await getSummaryData(
    [APIEndpoints.GET_ORDER_SUMMARY, APIEndpoints.GET_DELIVERY_SUMMARY],
    null,
    queryParams
  );
};

export const executionAPI = {
  fetchTargetExecution,
};
