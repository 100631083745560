import { useSelector } from 'react-redux';
import { keyBy, mapValues } from 'lodash';
import { useAccount } from './index';

/**
 * get list of all clients
 * @param flat - send true if u want as array else return keyBy dictionary
 * @return {*|Dictionary<SP>}
 */
export const useClients = (flat = false) => {
  const clients = useSelector(({ account }) => account.clients) || [];
  return flat ? clients : keyBy(clients, 'id');
};

/**
 * get list of all service providers
 * @param flat - send true if u want as array else return keyBy dictionary
 * @return {*|Dictionary<SP>}
 */
export const useServiceProviders = (flat = false) => {
  const spList = useSelector(
    ({ serviceProvider }) =>
      serviceProvider.listLight.map(
        ({
          id,
          businessName,
          name,
          registeredName,
          fulfillmentcycle,
          mobile,
          email,
          vendorType,
          businessVertical,
        }) => ({
          id,
          name: businessName || name || registeredName,
          fulfillmentcycle,
          mobile,
          email,
          vendorType,
          businessVertical,
        })
      ) || []
  );
  return flat ? spList : keyBy(spList, 'id');
};

export const useServiceProvidersWithType = (flat = false) => {
  const spList = useSelector(
    ({ serviceProvider }) =>
      serviceProvider.listLight.map(
        ({ id, businessName, fulfillmentcycle, mobile, email, vendorType }) => ({
          id,
          name: businessName,
          fulfillmentcycle,
          mobile,
          email,
          vendorType,
        })
      ) || []
  );
  return flat ? spList : keyBy(spList, 'id');
};

/**
 * get all customers (myself, clients, sp)
 * @param flat - send true if u want as array else return keyBy dictionary
 * @return {*|Dictionary<SP>}
 */
export const useCustomers = (flat = false) => {
  const { myCustomerId } = useAccount();
  const serviceProviders = useServiceProviders();
  const clients = useClients();
  const self = { [myCustomerId]: { id: myCustomerId, name: 'Self' } };
  const customers = { ...self, ...clients, ...serviceProviders };
  return flat ? mapValues(customers, (v, k) => v) : customers;
};
