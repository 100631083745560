import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import './BulkDelivery.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import addIcon from '../../../assets/img/add_icon_white.svg';
import BulkDeliveryModal from './BulkDeliveryModal';
import BulkDeliveryTable from './BulkDeliveryTable';
import BulkDeliveriesFilters from './BulkDeliveriesFilters';
import { useAsyncEffect } from '../../../hooks';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import {
  BATCH_COLUMN,
  BATCH_COLUMN_PRO,
  PROCESSED_BATCH_COLUMN,
  ALLOCATED_BATCH_COLUMN,
  REJECTED_BATCH_COLUMN,
  PROCESSED_BATCH_COLUMN_PRO,
  REJECTED_BATCH_COLUMN_PRO,
  ALLOCATED_BATCH_COLUMN_PRO,
} from './Columns/ColumnDef';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthority';
import { AUTHORITY, PAGE_CONFIG } from '../../../shared/Constants/Constants';
import { bulkUploadDocuments } from '../../../shared/Utils/FileUpload';
import IndividualDeliveryStatusModal from './IndividualDeliveryStatusModal';

const tabToStatusMap = {
  inProgress: 'UPLOADED,UPLOADING,EXTRACTED,IN_PROCESS,INITIATED',
  Processed: 'COMPLETED',
  Allocated: 'ALLOCATED',
  Rejected: 'REJECTED',
};

function BulkDeliveries() {
  let location = useLocation();
  const history = useHistory();
  const { hasAuth } = useAuthority();
  const isPro = hasAuth([AUTHORITY.PRO]);
  const isServiceProvider = hasAuth([AUTHORITY.SERVICE_PROVIDER]);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();
  const HASH = location?.hash?.replace('#', '');

  const { serviceProviders, accountDetails, allAccountDetails } = useSelector(
    ({ serviceProvider, account }) => ({
      serviceProviders: serviceProvider.listLight,
      accountDetails: account.user.profile,
      allAccountDetails: account,
    })
  );

  const [tableData, setTableData] = useState([]);
  const [uploaded, setUploaded] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tabelParams, setTabelParams] = useState({ pageKey: 0, size: 20, total: 0, pages: 0 });
  const [showModal, setShowModal] = useState(false);
  const [selectedDel, setSelectedDel] = useState();
  const [batchId, setBatchId] = useState();
  const [status, setStatus] = useState(tabToStatusMap[HASH !== '' ? HASH : 'inProgress']);
  const [{ batchID, provider }, setFilters] = useState({});

  const isCurrentYear =
    allAccountDetails?.selectedFinancialYear?.fromDate?.split('-')[0] === currentYear.toString();

  const yearDate = isCurrentYear
    ? allAccountDetails?.currentYear?.value
    : allAccountDetails?.selectedFinancialYear;

  const uploadDate = isCurrentYear
    ? `${currentYear}-${currentMonth}-${currentDate}`
    : allAccountDetails?.selectedFinancialYear?.toDate?.split('T')[0];

  useAsyncEffect(async () => {
    setIsLoading(true);
    getFilteredBatchDetails();
  }, [serviceProviders.length, status, allAccountDetails?.selectedFinancialYear?.fromDate]);

  const updateParams = (page = 0) => {
    const params = {
      size: 20,
      sort: 'uploadedAt,DESC',
      page,
      status: status,
    };

    setTabelParams((prev) => ({ ...prev, pageKey: page }));

    if (!isPro) params['providerId.equals'] = accountDetails?.id;
    if (isPro && !!provider) params['providerName.equals'] = provider;
    if (batchId === '') return params;
    if (batchID) params['batchId.equals'] = batchID;
    return params;
  };

  const spName = (i) =>
    serviceProviders?.find((item) => item?.id === i?.serviceProviderId)?.businessName;

  const getFilteredBatchDetails = async (args) => {
    const param = updateParams(args);
    const result = await DeliveryService.fetchBatchDetails(param);

    if (result.status >= 400) {
      setIsLoading(false);
      return;
    }

    if (result.status === 200) {
      setTabelParams((prev) => ({
        ...prev,
        total: result.headers['x-total-count'],
        pages: Math.ceil(+result.headers['x-total-count'] / PAGE_CONFIG.size),
      }));
      setIsLoading(false);
    }

    const newResult = result.data ?? [];
    const resultJson = newResult?.map((e) => ({ ...e, providerName: spName(e) }));

    setTableData(resultJson ?? []);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUploadSuccess = () => {
    setShowModal(false);
    getFilteredBatchDetails();
  };

  const filterChangeHandler = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value.trimStart() }));
  };

  const handleInProgress = (evn) => {
    const IDX = evn?.target?.getAttribute('id');
    setSelectedDel(tableData[IDX]);
    if (IDX) setShowModal(true);
  };

  const discardHandler = async (batchID, remark) => {
    const result = await DeliveryService.discardBatchHandler({ batchId: batchID, remarks: remark });

    if (result.status > 400) {
      toast.error('File deletion failed');
      return;
    }

    if (result.status === 200) {
      handleCloseModal();
      getFilteredBatchDetails();

      toast.success('File deleted successfully');
      return;
    }
  };

  const uploadHandler = async (id, name, content, recylerId, recyclerName) => {
    const result = await bulkUploadDocuments(
      id,
      name,
      content,
      uploadDate,
      recylerId,
      recyclerName
    );

    if (result.status > 400) {
      toast.error('Upload failed');
      return;
    }

    if (result.status === 200) {
      const batchid = result?.headers?.['x-batchid'];
      setBatchId(batchid);
      setUploaded(2);
      toast.success('Document uploaded successfully');
    }
  };

  const processDocumentHandler = async (batchID) => {
    const result = await DeliveryService.documentProcessHandler({ batchId: batchID });

    if (result.status > 400) {
      toast.error(result?.error?.message);
      return;
    }

    if (result.status === 200) {
      getFilteredBatchDetails();
    }
  };

  return (
    <>
      <Card.Header className="bg-white border-0 mb-2">
        <Row className="padding-aligned align-self-center">
          <Col className="d-flex align-self-center">
            <h3>Bulk Deliveries</h3>
          </Col>
          <Col className="text-right">
            <Button
              className="primary"
              onClick={() => {
                setShowModal(true);
                setSelectedDel();
              }}>
              <div className="bulk-btn">
                <img src={addIcon} alt="add_icon" />
                Create Batch
              </div>
            </Button>
            {selectedDel ? (
              <IndividualDeliveryStatusModal
                show={showModal}
                selectedDel={selectedDel}
                discardFn={discardHandler}
                handleClose={handleCloseModal}
                processFn={processDocumentHandler}
              />
            ) : (
              <BulkDeliveryModal
                setUploaded={setUploaded}
                uploaded={uploaded}
                show={showModal}
                batchId={batchId}
                discardFn={discardHandler}
                uploadHandler={uploadHandler}
                handleClose={handleCloseModal}
                handleUploadSuccess={handleUploadSuccess}
                processDocumentHandler={processDocumentHandler}
              />
            )}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-white border-top">
        <BulkDeliveriesFilters
          changeFn={filterChangeHandler}
          filterFn={getFilteredBatchDetails}
          provider={provider}
          isPro={isPro}
          isServiceProvider={isServiceProvider}
          setFilters={setFilters}
          batchID={batchID}
        />
        <Tabs
          defaultActiveKey="inProgress"
          activeKey={HASH !== '' ? HASH : 'inProgress'}
          onSelect={(e) => {
            setStatus(tabToStatusMap[e]);
            history.replace(`/bulk-delivery#${e}`);
          }}
          className="mb-3 bulk-delivery">
          <Tab eventKey="inProgress" title="In progress" onClick={(e) => handleInProgress(e)}>
            <BulkDeliveryTable
              isLoading={isLoading}
              DATA={tableData}
              TABLEPARAM={tabelParams}
              COLUMNDEF={isPro ? BATCH_COLUMN_PRO : BATCH_COLUMN}
              tableStateFn={setTabelParams}
              pageChangeFn={getFilteredBatchDetails}
              customAction={true}
              type="inprogress"
            />
          </Tab>
          <Tab eventKey="Processed" title="Processed">
            <BulkDeliveryTable
              isLoading={isLoading}
              DATA={tableData}
              TABLEPARAM={tabelParams}
              COLUMNDEF={isPro ? PROCESSED_BATCH_COLUMN_PRO : PROCESSED_BATCH_COLUMN}
              tableStateFn={setTabelParams}
              pageChangeFn={getFilteredBatchDetails}
              customAction={true}
              type="processed"
            />
          </Tab>
          <Tab eventKey="Allocated" title="Allocated">
            <BulkDeliveryTable
              isLoading={isLoading}
              DATA={tableData}
              TABLEPARAM={tabelParams}
              COLUMNDEF={isPro ? ALLOCATED_BATCH_COLUMN_PRO : ALLOCATED_BATCH_COLUMN}
              tableStateFn={setTabelParams}
              pageChangeFn={getFilteredBatchDetails}
              customAction={true}
              type="allocated"
            />
          </Tab>
          <Tab eventKey="Rejected" title="Rejected">
            <BulkDeliveryTable
              isLoading={isLoading}
              DATA={tableData}
              TABLEPARAM={tabelParams}
              COLUMNDEF={isPro ? REJECTED_BATCH_COLUMN_PRO : REJECTED_BATCH_COLUMN}
              tableStateFn={setTabelParams}
              pageChangeFn={getFilteredBatchDetails}
              customAction={true}
              type="rejected"
            />
          </Tab>
        </Tabs>
      </Card.Body>
    </>
  );
}

export default BulkDeliveries;
