import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { RcylDatePicker } from '../../../shared/Components';
import { isEmpty } from 'lodash';

const currentDate = new Date();

function BulkDeliveriesFilters({
  changeFn,
  filterFn,
  provider,
  isServiceProvider,
  isPro,
  setFilters,
  batchID,
}) {
  const [clearBtnClicked, setClearBtnClicked] = useState(false);

  const handleKeyDown = (event) => {
    const validCharacters = /[0-9BT]/;
    if (
      event.key.length === 1 &&
      !validCharacters.test(event.key) &&
      !event.metaKey &&
      !event.ctrlKey
    ) {
      event.preventDefault();
    }
  };

  const handleSPKeyDown = (event) => {
    const validCharacters = /[A-Za-z0-9 ]+/;
    if (
      event.key.length === 1 &&
      !validCharacters.test(event.key) &&
      !event.metaKey &&
      !event.ctrlKey
    ) {
      event.preventDefault();
    }
  };

  const handleClear = () => {
    setFilters({ batchID: '', provider: '' });
    setClearBtnClicked(true);
  };

  useEffect(() => {
    filterFn();
    setClearBtnClicked(false);
  }, [clearBtnClicked]);

  const isDisabled = () =>
    !isServiceProvider || isPro ? isEmpty(batchID) && isEmpty(provider) : isEmpty(batchID);

  return (
    <div className="bulk_filters">
      <Form.Group id="batchCreation" className="date-picker-bg-img-icon">
        <Form.Label className="label">Batch ID</Form.Label>
        <FormControl
          value={batchID}
          onChange={(e) => changeFn('batchID', e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </Form.Group>

      {(!isServiceProvider || isPro) && (
        <Form.Group>
          <Form.Label className="text-small">Recycler/Service Provider Name</Form.Label>
          <InputGroup>
            <FormControl
              placeholder="Enter"
              value={provider}
              onChange={(e) => {
                changeFn('provider', e.target.value);
              }}
              onKeyDown={(e) => handleSPKeyDown(e)}
            />
          </InputGroup>
        </Form.Group>
      )}

      <Button
        variant="primary"
        style={{ width: 'max-content', height: '100%' }}
        disabled={isDisabled()}
        onClick={() => filterFn()}>
        Apply
      </Button>
      <Button
        variant="primary"
        style={{ width: 'max-content', height: '100%' }}
        onClick={() => handleClear()}>
        Clear
      </Button>
    </div>
  );
}

export default BulkDeliveriesFilters;
