import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Card, Col, Row } from 'react-bootstrap';
import { ExecutionFilters, ExecutionTargetList } from '../../../features/Execution';
import { HasAuthority, SummaryViewToggleButton } from '../../../shared/Components';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getExecutionSummary } from '../../../state/Execution/selectors';
import './ExecutionContainer.scss';
import ExecutionTableLoader from '../../../features/Execution/ExecutionTargetList/ExecutionTableLoader';
import { fetchTargetExecution } from '../../../state/Execution/executionThunks';
import { ChangeView } from '../../../state/Execution/executionSlice';

const ExecutionContainer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { summary, filters, view, isFetching, currentFinancialYear } = useSelector(
    ({ execution, browseNodes, region, account }) => {
      return {
        ...execution,
        currentFinancialYear: account.selectedFinancialYear,
        summary: getExecutionSummary({
          ...execution,
          browseNodes: browseNodes.data,
          region: region.data,
          currentCustomerId: account.user?.profile?.id,
        }),
      };
    }
  );

  const handleViewChange = (view) => {
    dispatch(ChangeView(view));
  };

  useEffect(() => {
    loadSummaryData();
  }, [currentFinancialYear]);

  function loadSummaryData() {
    dispatch(fetchTargetExecution(currentFinancialYear));
  }

  return (
    <div className="animated fadeIn ExecutionContainer">
      <Card className="main-card">
        <Card.Header className="bg-white border-0  py-0">
          <Row className="padding-aligned align-self-center">
            <Col className="align-self-center" md={4}>
              <h4>Execution </h4>
            </Col>
            <Col className="text-right">
              <ButtonToolbar className="float-right">
                <HasAuthority authorities={['ROLE_SERVICE_PROVIDER']}>
                  <Button
                    onClick={() => {
                      history.push('executions/deliveries/create');
                    }}>
                    New delivery
                  </Button>
                </HasAuthority>
                <Button
                  onClick={() => {
                    history.push('work-orders/create');
                  }}>
                  New Work Order
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="border-top p-0">
          <PerfectScrollbar options={{ suppressScrollX: true }} style={{ height: '72vh' }}>
            <Row noGutters>
              <Col className="bg-secondary p-4">
                <ExecutionFilters data={summary} />
              </Col>
            </Row>
            <Row noGutters>
              <Col md={12} className="px-4 pt-4 text-right">
                <SummaryViewToggleButton handleViewChange={handleViewChange} activeView={view} />
              </Col>
              <Col className="p-4">
                {isFetching ? (
                  <ExecutionTableLoader />
                ) : (
                  <ExecutionTargetList
                    view={view}
                    loading={isFetching}
                    filters={filters}
                    data={summary}
                  />
                )}
              </Col>
            </Row>
          </PerfectScrollbar>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ExecutionContainer;
