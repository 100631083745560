/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';
import './App.scss';
import { Page404 } from './features';
import { useAppAuth } from './hooks/useAppAuth';
import AppLayout from './layout/AppLayout';
import { ServerException } from './shared/Components';
import Alert from './shared/Components/Alert';
import Loader from './shared/Components/Loader/Loader';
import AppAnalytics from './shared/Config/AppAnalytics';
import { AUTHORITY } from './shared/Constants/Constants';
import NetworkDetector from './shared/Utils/NetworkDetector';
import SelectCustomer from './views/SelectCustomer';
import { default as SupervisorLogin } from './views/_App/LoginPage/Login.screen';
import AppSelectCustomer from './views/AppSelectCustomer';
import { getAccount } from './state/Account/accountThunks';
import { fetchServiceProviders } from './state/ServiceProvider/serviceProviderThunks';
import { serviceWorkerUpdate } from './state/User/userSlice';

// Containers
const Layout = React.lazy(() => import('./layout/Layout'));

// Pages
const Auth = React.lazy(() => import('./views/Auth/Auth'));

const AppRoute = ({
  component: Component,
  isLoggedIn,
  serviceProviders,
  isAuthenticate,
  ...rest
}) => {
  const isAuth = useAppAuth();
  return (
    <Route
      render={(props) => {
        const sub = props.location.pathname.split('/');
        return !isAuth ? (
          isAuthenticate ? (
            <Component
              isAuthenticate={isAuthenticate}
              serviceProviders={serviceProviders}
              {...props}
            />
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          <Redirect exact to={`/supervisor/login/${sub[sub.length - 1]}`} />
        );
      }}
    />
  );
};

const DesktopRoute = ({ component: Component, isLoggedIn, isAuthenticate, ...rest }) => {
  return (
    <>
      <Route
        render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)}
      />
    </>
  );
};

const App = () => {
  const { serviceProvider, account, isServiceWorkerUpdated, serviceWorkerRegistration } =
    useSelector(({ serviceProvider, account, users }) => {
      return {
        serviceProvider,
        account,
        isServiceWorkerInitialized: users?.serviceWorkerInitialized,
        isServiceWorkerUpdated: users?.serviceWorkerUpdated,
        serviceWorkerRegistration: users?.serviceWorkerRegistration,
      };
    });

  const dispatch = useDispatch();

  useEffect(() => {
    const isTokenSet = !!localStorage.getItem('authenticate_token');
    AppAnalytics.init();
    if (isTokenSet) {
      dispatch(getAccount());
      dispatch(fetchServiceProviders());
    }
  }, []);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration?.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  const { isAuthenticate, user, isLoading } = account;
  const { listLight } = serviceProvider;
  const isTokenSet = !!localStorage.getItem('authenticate_token');
  const showLoader = (isTokenSet && !user) || (isTokenSet && !user && isLoading);
  const showSelectCustomer =
    !showLoader && !user?.profile?.id && user?.authorities?.includes(AUTHORITY.ADMIN);

  useEffect(() => {
    if (isServiceWorkerUpdated) {
      localStorage.clear();
      window.location.assign('/login');
    }
  }, [isServiceWorkerUpdated]);

  return (
    <>
      <div className="App-alert">
        {isServiceWorkerUpdated && (
          <Alert
            text="There is a new version available."
            buttonText="Update"
            dispatchingFunction={serviceWorkerUpdate}
            onClick={updateServiceWorker}
          />
        )}
      </div>
      <ToastContainer position="bottom-right" hideProgressBar={true} autoClose={5000} />
      <ServerException />
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <React.Suspense fallback={''}>
            {showLoader ? (
              <Loader className="app-loading" />
            ) : (
              <Switch>
                <Route
                  path="/login"
                  name="Login Page"
                  render={(props) => {
                    return isAuthenticate ? <Redirect to="/" /> : <Auth {...props} />;
                  }}
                />
                <Route
                  path="/register"
                  name="Login Page"
                  render={(props) => {
                    return isAuthenticate ? <Redirect to="/" /> : <Auth {...props} />;
                  }}
                />
                <Route
                  path="/reset-password"
                  name="Login Page"
                  render={(props) => {
                    return isAuthenticate ? <Redirect to="/" /> : <Auth {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/supervisor/login/:id"
                  name="Login Page"
                  component={SupervisorLogin}
                />
                {isMobile ? (
                  <>
                    <AppRoute
                      isAuthenticate={isAuthenticate}
                      path="/"
                      isLoggedIn={!!user}
                      component={AppLayout}
                      serviceProviders={listLight}
                    />
                    <Route
                      path="/select-customer"
                      name="Select Customer"
                      component={AppSelectCustomer}
                    />

                    {showSelectCustomer && (
                      <Redirect
                        exact
                        from="/tabs/dashboard"
                        to="/select-customer?masterLogin=true"
                      />
                    )}
                  </>
                ) : (
                  <>
                    <DesktopRoute
                      isAuthenticate={isAuthenticate}
                      path="/"
                      isLoggedIn={!!user}
                      component={Layout}
                    />
                    <Route
                      path="/select-customer"
                      name="Select Customer"
                      component={SelectCustomer}
                    />

                    {showSelectCustomer && (
                      <Redirect exact from="/dashboard" to="/select-customer" />
                    )}
                  </>
                )}
                <Route path="*" name="Not Found" render={(props) => <Page404 {...props} />} />
              </Switch>
            )}
          </React.Suspense>
        </QueryParamProvider>
      </BrowserRouter>
    </>
  );
};

export default NetworkDetector(App);
