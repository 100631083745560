import React from 'react';
import { StatusTag } from '../../../../shared/Components/StatusTag';
import { convertToMt, toLocalDateTime } from '../../../../shared/Utils/Helper';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CheckboxCell } from '../../../../shared/Components/GenericComponent';

export const BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <span
          id={cellInfo?.row?.id}
          className={!condition ? 'text-primary' : ''}
          style={{ color: condition ? 'gray' : '', cursor: condition ? 'none' : 'pointer' }}>
          {cellInfo?.cell?.value}
        </span>
      );
    },
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'Vendor Name',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (cellInfo) => {
      return <StatusTag status={cellInfo?.cell?.value} />;
    },
  },
];

export const BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <span
          id={cellInfo?.row?.id}
          className={!condition ? 'text-primary' : ''}
          style={{ color: condition ? 'gray' : '', cursor: condition ? 'none' : 'pointer' }}>
          {cellInfo?.cell?.value}
        </span>
      );
    },
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
  },
  {
    Header: 'ZIP File Name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>;
    },
  },
  {
    Header: 'Processing Status',
    accessor: 'status',
    Cell: (cellInfo) => {
      return <StatusTag status={cellInfo?.cell?.value} />;
    },
  },
];

export const PROCESSED_BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'Vendor (Recyler/Service Provider)',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>;
    },
  },
  {
    Header: "Total Draft DL's",
    accessor: 'totalTransactions',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: "DL's Allocated",
    accessor: 'totalDeliveries',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'No. of Invalid documents',
    accessor: 'invalidDocsCount',
    Cell: (cellInfo) => {
      return <span className="text-danger">{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
];

export const PROCESSED_BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>;
    },
  },
  {
    Header: "Total Draft DL's",
    accessor: 'totalTransactions',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: "DL's Allocated",
    accessor: 'totalDeliveries',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'No. of Invalid documents',
    accessor: 'invalidDocsCount',
    Cell: (cellInfo) => {
      return <span className="text-danger">{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
];

export const PENDING_BATCH_COLUMN = [
  {
    id: 'selection',
    Cell: ({ row }) => {
      const condition = row.original?.taggedFields === row.original?.totalTaggedFields;
      return (
        <div className="d-flex justify-content-center">
          <CheckboxCell
            getToggleSelectionProps={row.getToggleRowSelectedProps}
            disabled={condition}
          />
        </div>
      );
    },
  },
  {
    Header: 'Draft DL #',
    accessor: 'referenceId',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'Vendor name',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    Header: 'No. of Mandatory fields tagged',
    accessor: 'taggedFields',
    Cell: ({ row }) => (
      <span>
        {row.original?.taggedFields ?? 0} / {row.original?.totalTaggedFields ?? 0}
      </span>
    ),
  },
  {
    Header: 'Qty',
    accessor: 'unloadingNetWeight',
    Cell: ({ cell: { row } }) => {
      return <span>{`${convertToMt(row.original?.unloadingNetWeight ?? 0, 2)} MT`}</span>;
    },
  },
];

export const ALLOCATED_BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}?status=ALLOCATED`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
  },
  {
    Header: 'Batch Creation date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => <span>{toLocalDateTime(cellInfo?.cell?.value) ?? '-'}</span>,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    id: 'vendor',
    Header: 'Vendor (Recyler/Service Provider)',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    Header: 'Zip file name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    id: 'recycler',
    Header: 'Vendor Name',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    Header: 'Workorder ID',
    accessor: 'workOrderIds',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value?.join(', ') ?? '-'}</span>,
  },
  {
    Header: 'Total Qty allocated',
    accessor: 'totalQty',
    Cell: (cellInfo) => <span>{convertToMt(cellInfo?.cell?.value ?? 0, 2)} MT</span>,
  },
];

export const ALLOCATED_BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}?status=ALLOCATED`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
  },
  {
    Header: 'Batch Creation date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => <span>{toLocalDateTime(cellInfo?.cell?.value) ?? '-'}</span>,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    Header: 'Zip file name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    id: 'recycler',
    Header: 'Vendor Name',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value ?? '-'}</span>,
  },
  {
    Header: 'Workorder ID',
    accessor: 'workOrderIds',
    Cell: (cellInfo) => <span>{cellInfo?.cell?.value?.join(', ') ?? '-'}</span>,
  },
  {
    Header: 'Total Qty allocated',
    accessor: 'totalQty',
    Cell: (cellInfo) => <span>{convertToMt(cellInfo?.cell?.value ?? 0, 2)} MT</span>,
  },
];

export const REJECTED_BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'Service Provider',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>;
    },
  },
  {
    Header: 'Reject Reasons',
    accessor: 'remarks',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
];

export const REJECTED_BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value}</span>;
    },
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'Service Provider',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>;
    },
  },
  {
    Header: 'Reject Reasons',
    accessor: 'remarks',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
];

export const ALLOCATED_SUMMARY = [
  {
    Header: 'Service Provider',
    accessor: 'spName',
  },
  {
    Header: 'Recycler',
    accessor: 'recyName',
  },
  {
    Header: 'Allocation Date & Time',
    accessor: 'allocatedDate',
  },
  {
    Header: 'Work Order',
    accessor: 'woId',
  },
  {
    Header: 'DLs Allocated',
    accessor: 'dlCount',
  },
  {
    Header: 'Allocated QTY',
    accessor: 'allocatedQty',
    Cell: (cellInfo) => <span>{convertToMt(cellInfo?.cell?.value ?? 0, 3) ?? '-'} MT</span>,
  },
];
