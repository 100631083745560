/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DashboardExecutionOverview from '../../features/Dashboard/DashboardExecutionOverView';
import DashboardOverViewByState from '../../features/Dashboard/DashboardOverViewByState';
import DashboardMaterialItemsFilters from '../../features/Dashboard/DashboardMaterialItemsFilter';
import TargetStepCard from '../../features/Dashboard/TargetStepCard/TargetStepCard';
import {
  brandCards,
  serviceProviderCards,
} from '../../features/Dashboard/TargetStepCard/_target-data';
import './Dashboard.scss';
import { ErrorBoundary } from '../../shared/Config/ErrorBoundary';
import Loader from '../../shared/Components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStats } from '../../state/Brand/brandThunks';
import { AccountSelectors } from '../../state/Account';
import { AUTHORITY } from '../../shared/Constants/Constants';
import QuantityOverview from '../../features/Dashboard/QuantityOverview/QuantityOverview';
import SustainabilityImpact from '../../features/Dashboard/SustainabilityImpact';
import { getCustomerWiseSummary, getDashboardSummary } from '../../state/Execution/selectors';
import Sidebar from 'react-sidebar';
import { HasAuthority, SummaryViewToggleButton } from '../../shared/Components';
import * as moment from 'moment';
import { useAuthority } from '../../shared/Components/HasAuthority/HasAuthority';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import DashboardOverviewByCustomer from '../../features/Dashboard/OverviewByCustomer/DashboardOverviewByCustomer';
import { fetchTargetExecution } from '../../state/Execution/executionThunks';
import { ChangeView } from '../../state/Execution/executionSlice';

const Dashboard = (props) => {
  const { brand, account, isUserBrand } = useSelector(({ brand, account }) => ({
    brand,
    account,
    isUserBrand: AccountSelectors.isUserBrand(account),
  }));
  const dispatch = useDispatch();

  const [steps, setSteps] = useState([]);
  const [workOrderVal, setWorkOrder] = useState();
  const [incomingWorkOrders, setIncomingWorkOrders] = useState();

  const [loader] = useState(false);
  const [showTimeline] = useState(false);
  const [createdDateBasedFY, setCreatedDateBasedFY] = useState();
  const [financialYearNew, setFinancialYearNew] = useState();

  const currentFinancialYear = account.selectedFinancialYear;
  const { hasAuth } = useAuthority();
  const isBrand = hasAuth([AUTHORITY.BRAND]);

  const clientRef = useRef();
  const vendorRef = useRef();

  // const myId = useSelector(({ account }) => account.user?.profile?.id);

  function loadSummaryData() {
    dispatch(fetchTargetExecution(currentFinancialYear));
  }

  useEffect(() => {
    loadSummaryData();
    dispatch(
      fetchStats({
        fromDate: account?.selectedFinancialYear?.fromDate,
        toDate: account?.selectedFinancialYear?.toDate,
      })
    );
  }, [currentFinancialYear]);

  const { filters, view } = useSelector(({ execution }) => execution);
  const { summary, stateWiseSummary, facets } = useSelector(getDashboardSummary);
  const { clientWiseSummary, vendorWiseSummary } = useSelector(getCustomerWiseSummary);

  // function sendDataToAnalytics(data) {
  //   const _data = {
  //     hasTarget: data.targetsByMaterial,
  //     hasReceivedWorkOrder: data.incomingWorkOrders,
  //     hasServiceProvider: data.serviceProviders,
  //     hasCreatedWorkOrder: data.workOrders,
  //     hasAnyDocument: data.documents,
  //     hasUsers: data.users,
  //   };
  //   AppAnalytics.sendExtraData(_data);
  // }

  useEffect(() => {
    //Onboarding stats
    if (isUserBrand) {
      const brandSteps = [...brandCards];
      brandSteps[0].status = brand.stats.targetsByMaterial;
      brandSteps[1].status = brand.stats.targetsByGeographies;
      brandSteps[2].status = brand.stats.serviceProviders;
      brandSteps[3].status = brand.stats.workOrders;
      // brandSteps[4].status = brand.stats.documents;
      brandSteps[4].status = brand.stats.users;
      setSteps(brandSteps);
    } else {
      const serviceProvideSteps = [...serviceProviderCards];
      serviceProvideSteps[0].status = brand.stats.serviceProviders;
      serviceProvideSteps[1].status = brand.stats.workOrders;
      serviceProvideSteps[2].status = brand.stats.users;
      setSteps(serviceProvideSteps);
    }
  }, [brand?.stats, account]);

  //Onboarding stats
  useEffect(() => {
    setWorkOrder(brand?.stats?.workOrders);
    setIncomingWorkOrders(brand?.stats?.incomingWorkOrders);
  }, [brand?.stats?.workOrders, brand?.stats?.targetsByMaterial]);

  const handleViewChange = (view) => {
    dispatch(ChangeView(view));
  };

  useEffect(() => {
    setFinancialYearNew(
      moment(account?.selectedFinancialYear?.fromDate).isSame(
        moment(account?.financialYears[4].value.fromDate)
      )
    );
    setCreatedDateBasedFY(
      moment(account?.user.createdDate).isBetween(
        moment(account?.selectedFinancialYear?.fromDate),
        moment(account?.selectedFinancialYear?.toDate)
      )
    );
  }, [account?.selectedFinancialYear]);

  const handleBackClick = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ErrorBoundary>
      {brand?.isFetchingStats ? (
        <Loader />
      ) : (workOrderVal && isUserBrand) || (incomingWorkOrders && !isUserBrand) ? (
        <div className="Dashboard animated fadeIn">
          <Suspense fallback={<Loader />}>
            {/*HEADER*/}
            <Row className="sticky-page-header dashboard-header">
              <Col xs={9}>
                <h5 className="pull-left mt-1">Dashboard</h5>
                <div className="pull-right">
                  <SummaryViewToggleButton handleViewChange={handleViewChange} activeView={view} />
                </div>
              </Col>
            </Row>

            {/*CONTENT*/}
            <Sidebar
              pullRight={true}
              docked={showTimeline}
              open={false}
              sidebarClassName="timeline-sidebar">
              <div className="page-container">
                <Card>
                  <DashboardMaterialItemsFilters
                    filters={filters}
                    facetList={facets}
                    loader={loader}
                    financialYearNew={financialYearNew}
                  />
                  <Card.Body className="p-0">
                    <QuantityOverview data={summary} filters={filters} />

                    {!isBrand && (
                      <>
                        <Row className="m-0">
                          <Col className="p-0" xs={6}>
                            <h4
                              className="px-4 pt-4 pb-2"
                              style={{ backgroundColor: '#f9f9f9' }}
                              ref={clientRef}>
                              CLIENT OVERVIEW{' '}
                            </h4>
                          </Col>
                          <Col style={{ backgroundColor: '#f9f9f9' }} xs={6}>
                            <div
                              className="float-right pt-4 text-small"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleBackClick(vendorRef)}>
                              Jump to Vendor
                              <FontAwesomeIcon
                                className="ml-1"
                                style={{ color: '#005dff' }}
                                icon={faArrowDown}
                              />
                            </div>
                          </Col>
                        </Row>

                        <DashboardExecutionOverview data={summary} direction={'given'} />
                        {!!filters?.browseNodeIds && filters?.browseNodeIds[0] !== '3' && (
                          <>
                            <div className="divider" />
                            <DashboardOverViewByState data={stateWiseSummary} direction={'given'} />
                          </>
                        )}
                        <div className="divider" />
                        {
                          <DashboardOverviewByCustomer
                            data={clientWiseSummary}
                            direction={'given'}
                            title={'Client Fulfilment Progress'}
                          />
                        }
                      </>
                    )}

                    <div className="divider"></div>
                    <HasAuthority authorities={[AUTHORITY.SERVICE_PROVIDER]}>
                      <Row className="m-0">
                        <Col className="p-0" xs={6}>
                          <h4
                            className="px-4 pt-4 pb-2"
                            style={{ backgroundColor: '#f9f9f9' }}
                            ref={vendorRef}>
                            VENDOR OVERVIEW{' '}
                          </h4>
                        </Col>
                        <Col style={{ backgroundColor: '#f9f9f9' }} xs={6}>
                          <div
                            className="float-right pt-4 text-small"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleBackClick(clientRef)}>
                            Jump to Client
                            <FontAwesomeIcon
                              className="ml-1"
                              style={{ color: '#005dff' }}
                              icon={faArrowUp}
                            />
                          </div>
                        </Col>
                      </Row>
                    </HasAuthority>

                    <DashboardExecutionOverview
                      data={summary}
                      direction={'received'}
                      isBrand={isBrand}
                    />
                    {!!filters?.browseNodeIds && filters?.browseNodeIds[0] !== '3' && (
                      <>
                        <div className="divider"></div>
                        <DashboardOverViewByState
                          data={stateWiseSummary}
                          direction={'received'}
                          isBrand={isBrand}
                        />
                      </>
                    )}
                    <div className="divider"></div>
                    {!isBrand && (
                      <>
                        <DashboardOverviewByCustomer
                          data={vendorWiseSummary}
                          direction={'received'}
                          title={'Vendor Performance'}
                        />
                      </>
                    )}

                    {isBrand && <SustainabilityImpact data={summary} />}
                  </Card.Body>
                </Card>
              </div>
            </Sidebar>
          </Suspense>
        </div>
      ) : (
        ((workOrderVal !== undefined && workOrderVal === false && isUserBrand) ||
          (incomingWorkOrders !== undefined && incomingWorkOrders === false && !isUserBrand)) && (
          /*Onboarding - show when there is no data for dashboard*/
          <Suspense fallback={<Loader />}>
            <Card className="main-card">
              <Card.Header>
                {(createdDateBasedFY !== undefined && createdDateBasedFY) ||
                financialYearNew === false ? (
                  <>
                    <h5 className="heading">Welcome to Recykal EPR platform</h5>
                    <div className="heading">Let us get you started</div>
                  </>
                ) : (
                  financialYearNew &&
                  createdDateBasedFY === false && (
                    <h5 className="heading">
                      There are no targets defined for Financial Year 21-22. To check the Fulfilment
                      status of FY 20-21, kindly change the financial year at the top right corner.
                    </h5>
                  )
                )}
              </Card.Header>
              <Card.Body>
                <Row>
                  {steps.map((step, idx) => {
                    return (
                      <Col xs={12} lg={3} className="target-col m-2" key={idx}>
                        <TargetStepCard data={step} action={() => props.history.push(step.url)} />
                      </Col>
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>
          </Suspense>
        )
      )}
    </ErrorBoundary>
  );
};

export default Dashboard;
