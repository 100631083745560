import React from 'react';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Select from 'react-select';
import { useFormik } from 'formik';
import ExecutionFacets from './ExecutionFacets';
import { HasAuthority } from '../../../shared/Components';
import { AUTHORITY } from '../../../shared/Constants/Constants';
import { applySummaryFilter } from '../../../state/Execution/executionSlice';

const ExecutionFilters = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clients, serviceProviders, filters } = useSelector(
    ({ account, serviceProvider, execution }) => ({
      clients: account.clients,
      serviceProviders: serviceProvider.listLight,
      filters: execution.filters,
    })
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filters,
    },
    onSubmit: (values) => {
      dispatch(applySummaryFilter(values));
    },
  });
  console.log(filters);
  return (
    <div className="ExecutionFilter">
      <Form noValidate>
        <Form.Row>
          <Form.Group as={Col} xs="12" md="6" sm="12">
            <Form.Label className="label text-regular">
              Filter by Material, State and District
            </Form.Label>
            <ExecutionFacets
              filters={filters}
              facetData={props.data}
              onChange={(value) => {
                formik.setFieldValue('itemIds', value?.itemIds);
                formik.setFieldValue('stateIds', value?.stateIds);
                formik.setFieldValue('districtIds', value?.districtIds);
                formik.setFieldValue('browseNodeIds', value?.browseNodeIds);
                formik.handleSubmit();
              }}
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" md="3" sm="12">
            <Form.Label className="label text-regular">Service Provider</Form.Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={true}
              value={serviceProviders.filter((i) => i.id === filters.serviceProviderId)}
              name="serviceProviderId"
              getOptionLabel={(option) => option['businessName']}
              getOptionValue={(option) => option['id']}
              options={serviceProviders}
              onChange={(value) => {
                formik.setFieldValue('serviceProviderId', value?.id);
                formik.handleSubmit();
              }}
            />
          </Form.Group>
          <HasAuthority authorities={[AUTHORITY.SERVICE_PROVIDER]}>
            <Form.Group as={Col} xs="12" md="3" sm="12">
              <Form.Label className="label text-regular">Client</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                isClearable={true}
                value={clients.filter((i) => i.id === filters.clientId)}
                name="clientId"
                getOptionLabel={(option) => option['name']}
                getOptionValue={(option) => option['id']}
                options={clients}
                onChange={(value) => {
                  formik.setFieldValue('clientId', value?.id);
                  formik.handleSubmit();
                }}
              />
            </Form.Group>
          </HasAuthority>
        </Form.Row>
      </Form>
    </div>
  );
};

export default ExecutionFilters;
