import React, { useState, Component, useEffect } from 'react';
import { Link, Redirect, Route, useHistory, useLocation, Switch } from 'react-router-dom';
import HomePage from '../views/_App/HomePage/HomePage.app';
import { ReactComponent as HomeIcon } from '../assets/img/_App/ic_home.svg';
import { ReactComponent as WoIcon } from '../assets/img/_App/ic_wo.svg';
import { ReactComponent as DeliveryIcon } from '../assets/img/_App/ic_delivery.svg';
import { ReactComponent as ProfileIcon } from '../assets/img/_App/profile.svg';
import { fetchRegionStates } from '../state/Region/regionThunks';

import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Container,
  IconButton,
  makeStyles,
  Toolbar,
} from '@material-ui/core';

import { Colors } from '../theme/Colors';
import { appRoutes } from '../routes';
import { HasAuthority } from '../shared/Components';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as NotificationIcon } from '../assets/img/_App/notification.svg';
import { DIM } from '../theme/Dims';
import DeliveryPage from '../views/_App/DeliveriesPage/DeliveryPage.app';
import IntransitDeliveryPage from '../views/_App/DeliveriesPage/IntransitDeliveriesPage.app';
import PlannedDeliveriesPage from '../views/_App/DeliveriesPage/PlannedDeliverPage.app';
import WoListPage from '../views/_App/WoListPage/WoListPage.app';
import ProfilePage from '../views/_App/ProfilePage/ProfilePage.app';
import { regionActions } from '../state/Region';
import IndexPage from '../views/_App/IndexPage';
import AppHeader from './AppHeader';
import { ChatService } from '../shared/Services/ChatService';
import { useAuthority } from '../shared/Components/HasAuthority/HasAuthority';
import { AUTHORITY } from '../shared/Constants/Constants';
import AppSelectCustomer from '../views/AppSelectCustomer';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: '70px',
    left: 0,
    zIndex: '3147483600',
  },
  main: {
    backgroundColor: Colors.appBgColor,
    height: '100vh',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'block',
    fontSize: '1.273rem',
  },
  header: { backgroundColor: Colors.white, color: '#2e374d' },
  container: {
    marginTop: DIM.headerHeight,
    overflow: 'hidden',
  },
  selected: {
    fill: Colors.primary,
    color: Colors.primary,
  },
});

const AppLayout = (props) => {
  const classes = useStyles();
  console.log(props);
  const { isLoggedIn, isAuthenticate, serviceProviders } = { ...props };
  const { hasAuth } = useAuthority();
  const masterLoginParam =
    new URLSearchParams(window.location.search).get('masterLogin') || 'false';
  const { account } = useSelector(({ account }) => ({
    account: account,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticate) {
      // dispatch(regionActions.fetchRegionStates());
      dispatch(fetchRegionStates());
    }
  }, [isAuthenticate, account?.user?.profile]);
  useEffect(() => {
    ChatService().hideChat();
  }, []);

  const isPRO = hasAuth([AUTHORITY.PRO]);

  return (
    <div className={classes.main}>
      <Container disableGutters className={classes.container}>
        {appRoutes.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(props) => (
                <>
                  <Helmet>
                    <title>{route.name} - EPR</title>
                  </Helmet>
                  <AppHeader />
                  <route.component {...props} />
                </>
              )}
            />
          ) : null;
        })}
        {masterLoginParam === 'true' ? (
          <AppSelectCustomer />
        ) : (
          <Route path={'/tabs'} render={(props) => <TabLayout {...props} />} />
        )}

        {isAuthenticate && (
          <Switch>
            <Redirect exact from="/" to={isPRO ? '/deliveries-list' : '/tabs/dashboard'} />
          </Switch>
        )}
      </Container>
    </div>
  );
};

function TabLayout(props) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState();
  const location = useLocation();
  const tabMap = {
    '/tabs/dashboard': 'Home',
    '/tabs/open-deliveries': 'Deliveries',
    '/tabs/open-wo': 'Work Orders',
    '/tabs/index': 'Index',
    '/tabs/intransit-deliveries': 'In Transit Deliveries',
    '/tabs/planned-deliveries': 'Planned Deliveries',
    '/tabs/profile': 'My Account',
  };
  return (
    <>
      <AppHeader leftRender={null} titleRender={() => tabMap[location.pathname]} />
      <Container disableGutters>
        <Route path={'/tabs/dashboard'} name={'Home'} component={HomePage} />
        <Route path={'/tabs/open-deliveries'} name={'Deliveries'} component={DeliveryPage} />
        <Route
          path={'/tabs/intransit-deliveries'}
          name={'Deliveries'}
          component={IntransitDeliveryPage}
        />
        <Route
          path={'/tabs/planned-deliveries'}
          name={'Deliveries'}
          component={PlannedDeliveriesPage}
        />

        <Route path={'/tabs/open-wo'} name={'Work Orders'} component={WoListPage} />
        <Route path={'/tabs/index'} component={IndexPage} />
        <Route path={'/tabs/profile'} name={'My Account'} component={ProfilePage} />
      </Container>

      <BottomNavigation
        value={activeTab}
        defaultValue={activeTab}
        className={classes.stickToBottom}
        onChange={(event, newValue) => {
          setActiveTab(newValue);
        }}
        showLabels>
        <BottomNavigationAction
          value="/tabs/dashboard"
          component={Link}
          to="/tabs/dashboard"
          label="Home"
          classes={{ selected: classes.selected }}
          icon={<HomeIcon style={{ height: '30px' }} />}
        />
        <BottomNavigationAction
          value="/tabs/open-wo"
          component={Link}
          to="/tabs/open-wo"
          classes={{ selected: classes.selected }}
          label="Work Orders"
          icon={<WoIcon style={{ height: '30px' }} />}
        />
        <BottomNavigationAction
          value="/tabs/open-deliveries"
          component={Link}
          to="/tabs/open-deliveries?tab=true"
          label="Deliveries"
          classes={{ selected: classes.selected }}
          icon={<DeliveryIcon style={{ height: '30px' }} />}
        />
        <BottomNavigationAction
          value="/tabs/profile"
          component={Link}
          to="/tabs/profile"
          label="Profile"
          classes={{ selected: classes.selected }}
          icon={<ProfileIcon style={{ height: '30px' }} />}
        />
      </BottomNavigation>
    </>
  );
}

export default AppLayout;
