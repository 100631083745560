import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import uploadIcon from '../../../assets/img/upload-white.svg';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthority';
import { AUTHORITY } from '../../../shared/Constants/Constants';
import { DeliveryService } from '../../../../src/features/Delivery/delivery.service';
import { useAccount } from '../../../hooks';

function UploadDocBody({ uploadFn }) {
  const { hasAuth } = useAuthority();
  const isPro = hasAuth([AUTHORITY.PRO]);
  const isServiceProvider = hasAuth([AUTHORITY.SERVICE_PROVIDER]);
  const account = useAccount();
  const isRecycler = account?.user?.profile?.vendorType === 'PWP';

  const { serviceProviders, accountDetails } = useSelector(({ serviceProvider, account }) => ({
    serviceProviders: serviceProvider.listLight,
    accountDetails: account.user.profile,
  }));

  const [providerDetails, setProviderDetails] = useState({
    providerId: isPro ? 0 : accountDetails.id,
    providerName: isPro ? '' : accountDetails.serviceProviderName,
  });

  const fetchRecyclers = async () => {
    if (providerDetails.providerId) {
      //set Loading
      setLoading(true);
      const response = await DeliveryService.getRecyclers({
        customerId: providerDetails.providerId,
      });
      setRecyclers(response.data);
      // Check if there is only one recycler and set it as default
      if (response.data.length === 1) {
        const singleRecycler = response.data[0];
        setRecyclerDetails({
          recyclerId: singleRecycler.id,
          recyclerName: singleRecycler.businessName,
        });
      }
      setLoading(false);
    }
  };

  const [recyclerDetails, setRecyclerDetails] = useState({
    recyclerId: '',
    recyclerName: '',
  });

  const [recyclers, setRecyclers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (isServiceProvider) {
      setProviderDetails({
        providerId: accountDetails.id,
        providerName: accountDetails.serviceProviderName,
      });
    }
  }, [isServiceProvider, accountDetails]);

  useEffect(() => {
    fetchRecyclers();
  }, [providerDetails.providerId]);

  const onDrop = async (event) => {
    if (!providerDetails.providerId && isPro) {
      toast.error('Service provider is not Selected');
      return;
    }
    if ((isPro && !recyclerDetails.recyclerId) || (!isRecycler && !recyclerDetails.recyclerId)) {
      toast.error('Please Select Recycler ');
      return;
    }

    uploadFn(
      event.target.files,
      isPro && providerDetails.providerId === 8651
        ? recyclerDetails.recyclerId
        : providerDetails.providerId,
      isPro && providerDetails.providerId === 8651
        ? recyclerDetails.recyclerName
        : providerDetails.providerName,
      isRecycler ? providerDetails.providerId : recyclerDetails.recyclerId,
      isRecycler ? providerDetails.providerName : recyclerDetails.recyclerName
    );
  };

  console.log(providerDetails, 'isServiceProvider');

  return (
    <Row>
      {isPro && (
        <Col>
          <Form.Group as={Col}>
            <Form.Label className="label text-regular font-weight-bold">
              Service Provider
            </Form.Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={true}
              value={serviceProviders.find((i) => i.id === providerDetails.providerId) || null}
              name="serviceProviderId"
              getOptionLabel={(option) => option.businessName}
              getOptionValue={(option) => option.id}
              options={serviceProviders?.filter((sp) => sp?.vendorType === 'SERVICE_PROVIDER')}
              onChange={(value) => {
                setProviderDetails({ providerName: value?.businessName, providerId: value?.id });
                setRecyclerDetails({ recyclerId: '', recyclerName: '' });
              }}
            />
          </Form.Group>
        </Col>
      )}

      {(isPro || !isRecycler) && (
        <Col>
          <Form.Group as={Col}>
            <Form.Label className="label text-regular font-weight-bold">Recycler</Form.Label>
            <Select
              className={`basic-single ${
                formSubmitted && !recyclerDetails.recyclerId ? 'is-invalid' : ''
              }`}
              classNamePrefix="select"
              isSearchable={true}
              isClearable={true}
              isLoading={loading}
              value={recyclers.find((i) => i.customerId === recyclerDetails.recyclerId)}
              name="recyclerId"
              getOptionLabel={(option) => option.businessName}
              getOptionValue={(option) => option.customerId}
              options={recyclers}
              onChange={(value) => {
                setRecyclerDetails({
                  recyclerId: value?.customerId,
                  recyclerName: value?.businessName,
                });
              }}
              isDisabled={recyclers.length === 1} // Disabled there is only one recycler
            />
            {formSubmitted && !recyclerDetails.recyclerId && (
              <div className="invalid-feedback">Recycler is Required</div>
            )}
          </Form.Group>
        </Col>
      )}
      <Col>
        <Form.Group as={Col}>
          <Form.Label className="label text-regular font-weight-bold">Upload Document</Form.Label>
          <label className="upload_label" htmlFor="bulk-upload-input">
            <img src={uploadIcon} alt="upload_icon" />
            Upload File
          </label>
          <input
            accept="application/vnd.rar,application/zip"
            onChange={onDrop}
            id="bulk-upload-input"
            type="file"
            aria-label="drag and drop area"
          />
          {formSubmitted && !recyclerDetails.recyclerId && isPro && (
            <div className="invalid-feedback">Please select a recycler before uploading.</div>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}

export default UploadDocBody;
