import React from 'react';
import ExecutionContainer from './views/Execution/ExecutionContainer/ExecutionContainer';
import { AUTHORITY } from './shared/Constants/Constants';
// import HomePage from './views/_App/HomePage/HomePage.app';
import DeliveryPageApp from './views/_App/DeliveriesPage/DeliveryPage.app';
import BulkDeliveryPage from './views/Execution/BulkDeliveries/BulkDeliveries';
import CreateDeliveryPage from './views/_App/CreateDeliveryPage/CreateDeliveryPage.app';
import WoStateListPage from './views/_App/WoStateListPage/WoStateListPage.app';
import WoListPage from './views/_App/WoListPage/WoListPage.app';
import SupervisorDeliveryPage from './views/_App/DeliveriesPage/SupervisorDeliveryPage.app';
import RequiresAttentionDeliveriesPage from './views/_App/DeliveriesPage/RequiresAttentionDeliveries.app';
import DeliveryFulfilmentExecutivePage from './views/_App/DeliveriesPage/DeliveryFulfilmentExecutivePage.app';

import SpDeliveryViewPage from './views/_App/DeliveriesPage/SpDeliveryView';
import GoogleMap from './views/_App/GoogleMap/GoogleMap.app';
import BulkWorkOrderTarget from './views/Target/BulkWorkOrderTarget';
import AvailableCreditsDetails from './features/Planning/MarketPlaceCredits/AvailableCreditsDetails';

const DeliveriesFactoryLocationPage = React.lazy(() =>
  import('./views/_App/DeliveriesFactoryLocationPage/DeliveriesFactoryLocationPage.app')
);

const ProfileInformationPage = React.lazy(() =>
  import('./views/_App/ProfilePage/ProfileInformationPage/ProfileInformationPage.app')
);

const DeliveriesBatchDetails = React.lazy(() =>
  import('./views/Execution/BulkDeliveries/DeliveriesBatchDetails')
);
const EditTrasactionDelivery = React.lazy(() =>
  import('./views/Execution/BulkDeliveries/TransactionEditScreen')
);

const Blank = React.lazy(() => import('./features/Blank/Blank'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const CustomerList = React.lazy(() => import('./views/Customers/CustomersIndex'));
const VendorList = React.lazy(() => import('./views/VendorList/VendorList'));
const VendorEdit = React.lazy(() => import('./views/VendorList/VendorEdit'));
const VendorOnboard = React.lazy(() => import('./views/VendorList/VendorOnboard'));
const UnitDetails = React.lazy(() => import('./views/VendorList/VendorUnitDetails'));
const CustomersProfile = React.lazy(() => import('./views/Customers/CustomersProfile'));
const Target = React.lazy(() => import('./views/Target/Target'));
const WorkOrderCreate = React.lazy(() =>
  import('./views/WorkOrder/WorkOrderCreate/WorkOrderCreate')
);
const WorkOrders = React.lazy(() => import('./views/WorkOrder/WorkOrders'));
// const WorkOrdersIncoming = React.lazy(() =>
//   import('./views/WorkOrder/WorkOrdersIncoming/WorkOrdersIncoming')
// );
const WorkOrderDetails = React.lazy(() =>
  import('./views/WorkOrder/WorkOrderDetails/WorkOrderDetails')
);
const FulfilmentPlan = React.lazy(() =>
  import('./views/WorkOrder/WorkOrderDetails/FulfilmentPlan')
);
// const WorkOrderDetailsIncoming = React.lazy(() =>
//   import('./views/WorkOrder/WorkOrderDetailsIncoming/WorkOrderDetailsIncoming')
// );
const ExecutionIndex = React.lazy(() => import('./views/Execution'));

const DeliveryPage = React.lazy(() => import('./views/Execution/Deliveries/Deliveries'));
const DeliveryViewPage = React.lazy(() => import('./views/Execution/DeliveryView/DeliveryView'));
const DeliveryCreateEditPage = React.lazy(() =>
  import('./views/Execution/DeliveryCreateAndEdit/DeliveryCreateAndEdit')
);
const DeliveryAssignPage = React.lazy(() =>
  import('./views/Execution/DeliveryAssign/DeliveryAssign')
);

const FulfillmentReportPage = React.lazy(() =>
  import('./views/Reports/FulfillmentReport/FulfillmentReport')
);

const CPCBReportPage = React.lazy(() => import('./views/Reports/CPCBReport/CPCBReport'));

const MonthlyFulfillmentReportPage = React.lazy(() =>
  import('./views/Reports/MonthlyFulFillmentContainer/MonthlyFulFillmentContainer')
);

const SelfDeclarationPage = React.lazy(() => import('./views/SelfDeclaration/SelfDeclarationPage'));

const SPCBLetterPage = React.lazy(() => import('./views/Reports/SPCBLetter/SpcbLetterPage'));
const SPCBManagementPage = React.lazy(() => import('./views/Reports/SPCBLetter/SpcbManagement'));
const SPCBPreviewPage = React.lazy(() => import('./views/Reports/SPCBLetter/SpcbPreviewPage'));

const Users = React.lazy(() => import('./views/Users/Users'));
const Profile = React.lazy(() => import('./views/Profile/Profile'));

const InvoiceMainComponent = React.lazy(() => import('./views/Invoices/InvoiceList/InvoiceList'));
const AddInvoiceComponent = React.lazy(() => import('./views/Invoices/InvoiceAdd/InvoiceAdd'));
const ViewInvoiceComponent = React.lazy(() => import('./views/Invoices/InvoiceView/InvoiceView'));
const PaymentsMainComponent = React.lazy(() => import('./views/Payments/PaymentList/PaymentList'));
const ViewPaymentsComponent = React.lazy(() => import('./views/Payments/PaymentView/PaymentView'));
// const SupervisorLoginPage = React.lazy(() => import('./views/_App/LoginPage/Login.screen'));
const UlbMainComponent = React.lazy(() => import('./views/Ulb/UlbList'));
const UlbViewComponent = React.lazy(() => import('./views/Ulb/UlbView/UlbView'));

const SelfDeclarationPreview = React.lazy(() =>
  import('./views/SelfDeclaration/SelfDeclarationPreview')
);

const CoProcessRecyklingPage = React.lazy(() =>
  import('./views/Reports/CoprocessRecykling/CoprocessRecyklingPage')
);

const RecyclerCoProcessorIndex = React.lazy(() =>
  import('./views/Reports/CoprocessRecykling/RecyclerCoProcessorIndex')
);

const InvoiceFulfillManagement = React.lazy(() =>
  import('./views/Finance/InvoiceFulfillManagement')
);

const InvoiceView = React.lazy(() => import('./views/Finance/InvoiceView'));
const PaymentView = React.lazy(() => import('./views/Finance/PaymentView'));
const AuditDelivery = React.lazy(() => import('./features/Delivery/DeliveryDetails/AuditDelivery'));
// const WorkOrderTarget = React.lazy(() => import('./views/Target/BulkWorkOrderTarget'));
const UnAllocatedReport = React.lazy(() =>
  import('./views/Reports/UnAllocatedReport/UnAllocatedReport')
);
const VendorOnboarding = React.lazy(() =>
  import('./views/CapacityBuilding/VendorOnboarding/VendorOnboarding')
);
const WorkArea = React.lazy(() => import('./views/CapacityBuilding/WorkArea/NewWorkArea'));
const Planning = React.lazy(() => import('./views/Planning/Planning'));
const PlanningView = React.lazy(() => import('./views/Planning/BrandwiseUnplanned'));
// const AvailableCredits = React.lazy(() =>
//   import('./features/Planning/MarketPlaceCredits/AvailableCreditsDetails')
// );
const PlanningDraftWOBrandwise = React.lazy(() =>
  import('./views/Planning/PlanningDraftWOBrandwise')
);
const BrandwisePlanned = React.lazy(() => import('./views/Planning/BrandwisePlanned'));
const ReplanningChangeAllocation = React.lazy(() =>
  import('./features/Planning/PlanningModule/ReplanningChangeAllocation')
);

const ExecutionModule = React.lazy(() => import('./views/ExecutionModule/ExecutionDashboard'));
const ExecutionBrandView = React.lazy(() => import('./views/ExecutionModule/ExecutionBrandView'));
const SuperAdmin = React.lazy(() => import('./views/SuperAdmin/SuperAdmin'));
const Credits = React.lazy(() => import('./views/Credits/ManageCredits/CreditsIndex'));
const AssignCredits = React.lazy(() => import('./views/Credits/AssignCredits/AssignCredits'));
const CreditsView = React.lazy(() => import('./views/Credits/CreditsView/CreditsView'));
const RecyclersList = React.lazy(() => import('./views/RecyclersList/RecyclersList'));
const CreateManualInvoice = React.lazy(() => import('./views/Finance/CreateManualInvoice'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    permissions: ['DASHBOARD'],
  },
  {
    path: '/reports/fulfillment/:id',
    exact: true,
    name: 'Fulfillment',
    component: FulfillmentReportPage,
  },
  {
    path: '/reports/monthlyfulfillment',
    exact: true,
    name: 'MonthlyFulfillment',
    component: MonthlyFulfillmentReportPage,
  },
  {
    path: '/reports/selfdeclaration',
    exact: true,
    name: 'Self Declaration',
    component: SelfDeclarationPage,
  },
  {
    path: '/finance/manualInvoice',
    exact: true,
    name: 'Create Advance Invoice',
    component: CreateManualInvoice,
  },
  {
    path: '/reports/selfdeclaration/:id',
    exact: true,
    name: 'Self Declaration',
    component: SelfDeclarationPage,
  },
  {
    path: '/reports/selfdeclarationpreview',
    exact: true,
    name: 'Self Declaration Preview',
    component: SelfDeclarationPreview,
  },
  {
    path: '/reports/spcbletter',
    exact: true,
    name: 'SPCB Letter',
    component: SPCBLetterPage,
  },
  {
    path: '/reports/coprocessingrecycler',
    exact: true,
    name: 'Co-Processing / Recykler',
    component: RecyclerCoProcessorIndex,
  },
  {
    path: '/reports/certificate-generation',
    exact: true,
    name: 'Co-Processing / Recykler',
    component: CoProcessRecyklingPage,
  },
  {
    path: '/reports/unallocated',
    exact: true,
    name: 'unallocatedreport',
    component: UnAllocatedReport,
  },
  {
    path: '/reports/spcbmanagement',
    exact: true,
    name: 'SPCB Management',
    component: SPCBManagementPage,
  },
  {
    path: '/reports/spcbpreviewpage',
    exact: true,
    name: 'SPCB Management',
    component: SPCBPreviewPage,
  },
  {
    path: '/reports/cpcb-report',
    exact: true,
    name: 'CPCB Report',
    component: CPCBReportPage,
    authorities: [AUTHORITY.BRAND],
  },
  {
    path: '/service-provider',
    exact: true,
    name: 'Service Provider',
    component: VendorList,
    permissions: ['SERVICE_PROVIDERS'],
  },
  {
    path: '/service-provider/onboard',
    name: 'Add Service Provider',
    component: VendorOnboard,
    permissions: ['SERVICE_PROVIDERS'],
  },
  {
    path: '/service-provider/:id',
    name: 'ServiceProvider Profile',
    component: VendorEdit,
    permissions: ['SERVICE_PROVIDERS'],
  },
  {
    path: '/vendor-unit-details/:unitID',
    name: 'Unit Details',
    component: UnitDetails,
    permissions: ['SERVICE_PROVIDERS'],
  },
  {
    path: '/client',
    exact: true,
    name: 'clients',
    component: CustomerList,
    permissions: ['CLIENTS'],
  },
  {
    path: '/client/:id',
    name: 'Clients Profile',
    component: CustomersProfile,
    permissions: ['CLIENTS'],
  },
  {
    path: '/blank',
    name: 'Blank',
    component: Blank,
  },
  {
    path: '/target',
    name: 'Targets',
    component: Target,
    permissions: ['TARGETS'],
    authorities: [AUTHORITY.BRAND],
  },
  {
    path: '/bulk-order-target',
    name: 'Bulk Order Targets',
    component: BulkWorkOrderTarget,
    permissions: ['TARGETS'],
    authorities: [AUTHORITY.BRAND],
  },
  {
    path: '/work-orders',
    exact: true,
    name: 'Work Orders',
    component: WorkOrders,
    permissions: ['WORK_ORDERS'],
  },
  {
    path: '/work-orders-incoming',
    exact: true,
    name: 'Received Work orders',
    component: WorkOrders,
    permissions: ['CLIENT_WORK_ORDERS'],
  },
  {
    path: '/work-orders/create',
    name: 'Create Work Order',
    exact: true,
    component: WorkOrderCreate,
    permissions: ['WORK_ORDERS'],
  },
  {
    path: '/work-orders/create/:id',
    name: 'Edit Work Order',
    component: WorkOrderCreate,
    permissions: ['WORK_ORDERS'],
  },
  {
    path: '/work-orders/edit/:id',
    name: 'Edit Work Order',
    component: WorkOrderCreate,
    permissions: ['WORK_ORDERS'],
  },
  {
    path: '/work-orders/:id',
    name: 'Work Order Details',
    component: WorkOrderDetails,
    permissions: ['WORK_ORDERS'],
  },
  {
    path: '/work-order/fulfilmentplan/:id',
    name: 'Fulfilment Plan',
    component: FulfilmentPlan,
  },
  {
    path: '/work-orders-incoming/fulfilmentplan/:id',
    name: 'Fulfilment Plan',
    component: FulfilmentPlan,
  },
  {
    path: '/work-orders-incoming/:id',
    exact: true,
    name: 'Received Work orders',
    component: WorkOrderDetails,
    permissions: ['CLIENT_WORK_ORDERS'],
  },
  {
    path: '/executions',
    name: 'Executions',
    component: ExecutionIndex,
    permissions: ['VIEW_DELIVERY'],
    children: [
      {
        path: '/executions/deliveries/:id/edit',
        name: 'Update Delivery',
        component: DeliveryCreateEditPage,
        permissions: ['CREATE_DELIVERY'],
      },
      {
        path: '/executions/deliveries/:id/assign',
        name: 'Assign Delivery',
        component: DeliveryAssignPage,
        permissions: ['ASSIGN_DELIVERY_TO_INCOMING_WORK_ORDERS'],
      },
      {
        path: '/executions/deliveries/create',
        name: 'Create Delivery',
        component: DeliveryCreateEditPage,
        permissions: ['CREATE_DELIVERY'],
      },
      {
        path: '/executions/deliveries/:id',
        name: 'Create Delivery',
        component: DeliveryViewPage,
        permissions: ['VIEW_DELIVERY'],
      },
      {
        path: '/executions/deliveries',
        name: 'Deliveries',
        component: DeliveryPage,
        permissions: ['EXECUTION'],
      },
      {
        path: '/executions',
        name: 'Execution',
        exact: true,
        component: ExecutionContainer,
        permissions: ['EXECUTION'],
      },
    ],
  },
  {
    path: '/deliveries',
    name: 'Deliveries',
    exact: true,
    component: DeliveryPage,
    permissions: ['VIEW_DELIVERY'],
  },
  {
    path: '/bulk-delivery',
    name: 'Bulk Delivery',
    exact: true,
    component: BulkDeliveryPage,
    permissions: ['VIEW_DELIVERY'],
  },
  {
    path: '/bulk-delivery/:batch',
    name: 'Bulk Delivery',
    exact: true,
    component: DeliveriesBatchDetails,
    permissions: ['VIEW_DELIVERY'],
  },
  {
    path: '/bulk-delivery/edit/:id',
    name: 'Bulk Delivery Edit',
    exact: true,
    component: EditTrasactionDelivery,
    permissions: ['VIEW_DELIVERY'],
  },
  {
    path: '/audit-delivery/:id',
    name: 'Audit Delivery',
    exact: true,
    component: AuditDelivery,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    permissions: ['USERS', 'ROLES'],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/payables',
    name: 'Payables',
    exact: true,
    component: InvoiceMainComponent,
    authorities: [AUTHORITY.PRO, AUTHORITY.BRAND],
  },
  {
    path: '/payables/:id',
    name: 'View Payables',
    component: ViewInvoiceComponent,
  },
  {
    path: '/receivables',
    name: 'Receivables',
    exact: true,
    component: InvoiceMainComponent,
    authorities: [AUTHORITY.SERVICE_PROVIDER],
    noAuthorities: [AUTHORITY.PRO],
  },
  {
    path: '/receivables/add',
    name: 'Add Invoice',
    exact: true,
    component: AddInvoiceComponent,
  },
  {
    path: '/receivables/:id/edit',
    name: 'Edit Invoice',
    component: AddInvoiceComponent,
  },
  {
    path: '/receivables/:id',
    name: 'View Invoice',
    component: ViewInvoiceComponent,
  },
  {
    path: '/payments',
    name: 'Payments',
    exact: true,
    component: PaymentsMainComponent,
  },
  {
    path: '/payments/:id',
    name: 'View Payments',
    exact: true,
    component: ViewPaymentsComponent,
  },
  {
    path: '/ulb',
    name: 'ULB',
    exact: true,
    component: UlbMainComponent,
  },
  {
    path: '/ulb/:id',
    name: 'View Ulb',
    exact: true,
    component: UlbViewComponent,
  },
  {
    path: '/ulb/add',
    name: 'Add Ulb',
    component: UlbViewComponent,
  },
  {
    path: '/finance',
    name: 'FINANCE',
    exact: true,
    component: InvoiceFulfillManagement,
  },
  {
    path: '/finance/:id',
    name: 'View Invoice',
    exact: true,
    component: InvoiceView,
  },
  {
    path: '/finance/payment/:id',
    name: 'View Invoice',
    exact: true,
    component: PaymentView,
  },

  {
    path: '/vendor-onboarding',
    name: 'VendorOnboarding',
    exact: true,
    component: VendorOnboarding,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/execution-brand-vendor-fulfilment-details',
    name: 'Execution',
    exact: true,
    component: ExecutionModule,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/execution-brand-vendor-fulfilment-details/:id',
    name: 'Execution Details',
    exact: true,
    component: ExecutionBrandView,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/work-area',
    name: 'WorkArea',
    exact: true,
    component: WorkArea,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/planning',
    name: 'Planning',
    exact: true,
    component: Planning,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/Unplanned-Brandwise/:id',
    name: 'UnplannedBrandwise',
    exact: true,
    component: PlanningView,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/PlanningDraftWO-Brandwise/:id',
    name: 'PlanningDraftWOBrandwise',
    exact: true,
    component: PlanningDraftWOBrandwise,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/planned/:id',
    name: 'plannedBrandwise',
    exact: true,
    component: BrandwisePlanned,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/replanning-changeAllocation',
    name: 'plannedBrandwise',
    exact: true,
    component: ReplanningChangeAllocation,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/planned/changeAllocation/:id/assign',
    name: 'Update Delivery',
    component: DeliveryAssignPage,
    permissions: ['CREATE_DELIVERY'],
  },
  {
    path: '/availableCredits-Details',
    name: 'AvailableCredits Details',
    exact: true,
    component: AvailableCreditsDetails,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/superAdmin',
    name: 'Super Admin',
    exact: true,
    component: SuperAdmin,
    permissions: ['SUPERUSER'],
  },
  {
    path: '/manage-credits',
    name: 'Manage Credits',
    exact: true,
    component: Credits,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/assign-credits',
    name: 'Assign Credits',
    exact: true,
    component: AssignCredits,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/credits-view',
    name: 'Credits View',
    exact: true,
    component: CreditsView,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/credits-view',
    name: 'Credits View',
    exact: true,
    component: CreditsView,
    authorities: [AUTHORITY.PRO],
  },
  {
    path: '/recyclers-list',
    name: 'Recyclers List',
    exact: true,
    component: RecyclersList,
    authorities: [AUTHORITY.PRO],
  },
];

export const appRoutes = [
  {
    path: '/delivery-list',
    name: 'Delivery List',
    exact: true,
    component: DeliveryPageApp,
  },
  {
    path: '/create-delivery',
    name: 'Create Delivery',
    exact: true,
    component: CreateDeliveryPage,
    showTabs: false,
  },
  {
    path: '/update-delivery/:id',
    name: 'Update Delivery',
    exact: true,
    component: CreateDeliveryPage,
    showTabs: false,
    hideHeader: true,
  },
  {
    path: '/wo-state-list',
    name: 'State List',
    exact: true,
    component: WoStateListPage,
    showTabs: false,
  },
  {
    path: '/open-wo',
    name: 'Select Work Order',
    exact: true,
    component: WoListPage,
    showTabs: false,
  },
  {
    path: '/supervisor/deliveries/:id',
    name: 'Delivery',
    exact: true,
    component: SupervisorDeliveryPage,
    showTabs: false,
    hideHeader: true,
  },
  {
    path: '/require-attention-list',
    name: 'Requires your Attention',
    exact: true,
    component: RequiresAttentionDeliveriesPage,
  },
  {
    path: '/deliveries-list',
    name: 'Fulfilment Executive Delviery',
    exact: true,
    component: DeliveryFulfilmentExecutivePage,
  },
  {
    path: '/delivery-view/:id',
    name: 'Requires your Attention',
    exact: true,
    component: SpDeliveryViewPage,
  },
  {
    path: '/map',
    name: 'Map',
    exact: false,
    component: GoogleMap,
  },
  {
    path: '/DeliveriesFactoryLocation',
    name: 'DeliveriesFactoryLocation',
    exact: false,
    component: DeliveriesFactoryLocationPage,
  },
  {
    path: '/profile/information',
    name: 'Personal Info',
    exact: true,
    component: ProfileInformationPage,
  },
];
export default routes;
