import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Typography,
  Link,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
  makeStyles,
  Paper,
  Button,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as CompanyIcon } from '../../../../assets/img/_App/company.svg';
import { ReactComponent as TruckIcon } from '../../../../assets/img/_App/input_form.svg';
import { ReactComponent as DriverIcon } from '../../../../assets/img/_App/driver.svg';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: 'end',
  },
  mainHead: {
    color: '#2e384d',
    fontFamily: 'font-semibold',
  },
  linkStyl: {
    color: '#33ab2e',
  },
  small: {
    color: '#fff',
    backgroundColor: '#33ab2e',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  justifyCenter: {
    // justifyContent: 'center',
  },
  inlineflex: {
    display: 'inline-flex',
  },
  display: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sendSmsButton: {
    borderRadius: '25px',
    backgroundColor: '#33ab2e',
    fontWeight: 'bold',
    height: '32px',
    color: '#fff',
  },
}));

const DeliveryTranporterDetails = (props) => {
  const classes = useStyles();
  const {
    data,
    showEditChange,
    transporterValue,
    isEditable,
    isPRO,
    doSendSMSToDriver,
    value,
    sendSMS,
  } = {
    ...props,
  };
  const sendSMSTODRIVER = () => {
    if (data?.driverMobile) doSendSMSToDriver();
    else toast.warn('Driver mobile number is missing');
  };

  return (
    <Paper elevation={0} className="mt-3">
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography className={`${classes.mainHead} mb-2`} variant="h5" color="textPrimary">
                Transporter,Driver & Vehicle
              </Typography>
            }
          />
          {!isPRO && (
            <ListItemSecondaryAction>
              <ListItemText
                primary={
                  isEditable && (
                    <Typography className={`${classes.alignRight} mb-2`}>
                      <Link style={{ color: '#33ab2e' }} onClick={showEditChange}>
                        Change
                      </Link>
                    </Typography>
                  )
                }
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Divider />
        <ListItem alignItems="center">
          <ListItemIcon className={classes.justifyCenter}>
            <CompanyIcon />
          </ListItemIcon>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>Transporter</Typography>
                <Typography component="span" variant="body2" color="textPrimary">
                  {transporterValue?.find((e) => e.id === data?.transporterId)?.name || 'NA'}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemIcon className={classes.justifyCenter}>
            <TruckIcon />
          </ListItemIcon>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>Truck Number</Typography>
                <Typography component="span" variant="body2" color="textPrimary">
                  {data?.pickUpVehicleNumber || 'NA'}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>

        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemIcon className={classes.justifyCenter}>
            <DriverIcon />
          </ListItemIcon>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>Driver</Typography>
                <Typography component="span" variant="body2" color="textPrimary">
                  {data?.driverName || 'NA'}
                </Typography>
              </React.Fragment>
            }
          />{' '}
          <ListItemSecondaryAction className={classes.inlineflex}>
            {/* <Avatar className={classes.small}>
              <FontAwesomeIcon className={''} icon={faComments} size="sm" />
            </Avatar>
            <Avatar className={`${classes.small} ml-2`}>
              <FontAwesomeIcon className={''} icon={faPhoneAlt} size="sm" />
            </Avatar> */}
            {!isPRO && isEmpty(value) && sendSMS && (
              <Button
                variant="contained"
                className={`${classes.sendSmsButton} ml-2`}
                onClick={sendSMSTODRIVER}>
                Send SMS
              </Button>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  );
};

export default DeliveryTranporterDetails;
